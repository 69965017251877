







































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PageBase } from '@/core/models/shared';
import { OrdemServicoService } from '@/core/services/residuo';


@Component
export default class DetalharRelatorioLocacao extends PageBase {
    
  locacoes: any[] = [];
  service: OrdemServicoService = new OrdemServicoService();
  loading: boolean = false;
  filtro: any = {
    numOS: null,
    clienteId: null,
    embarcacaoId: null,
    equipamentoId: null,
    equipamentoTrocaId: null,
    dataInicio: null,
    dataFim: null,
    ordenacao: null
  }

  created() {
    this.filtro.numOS = this.$route.query.numOS,
    this.filtro.clienteId = this.$route.query.clienteId,
    this.filtro.embarcacaoId = this.$route.query.embarcacaoId,
    this.filtro.equipamentoId = this.$route.query.equipamentoId,
    this.filtro.equipamentoTrocaId = this.$route.query.equipamentoTrocaId,
    this.filtro.dataInicio = this.$route.query.dataInicio,
    this.filtro.dataFim = this.$route.query.dataFim,
    this.filtro.ordenacao = this.$route.query.ordenacao
  }

  mounted() {
    this.Carregar();
  }

  Carregar(){
    this.loading = true;
    this.service.RelatorioLocacao(this.filtro.numOS, this.filtro.clienteId, this.filtro.embarcacaoId, this.filtro.equipamentoId, this.filtro.equipamentoTrocaId, this.filtro.dataInicio, this.filtro.dataFim, this.filtro.ordenacao).then(
      res => {
        this.locacoes = res.data;
      },
      err => {
        this.$swal('Aviso', err.message, 'error')
      }
    ).finally(() => {
      this.loading = false;
    })
  }

}  

